import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import locales from "../../constants";

const Prodinity = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Prodinity",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/prodinity_og.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #E36393, #B1446D, #AF446C, #CD4D7D, #E16090 )",
        icons: "#e42978",
        navClass: "prodinity__nav",
        ogImage: require("../../assets/img/portfolio/piotrus_pan_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/prodinity/",
      }}
    >
      <PortfolioHeader name="prodinity" height="260" />
      <div className="prodinity">
        <section
          className="container-fluid portfolio-intro prodinity-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Prodinity</h1>
                <ul>
                  <li>Strategia</li>
                  <li>Rebranding (naming, claim)</li>
                  <li>Logo</li>
                  <li>Key visual</li>
                  <li>Strona internetowa</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  We współpracy z naszym Klientem, firmą Prodinity (wcześniej
                  BIOLAP Limited) przeprowadziliśmy proces pełnego rebrandingu
                  globalnej marki działającej na rynku IT, która agreguje
                  ogromne ilości danych i adaptuje systemy zarządzania do
                  potrzeb swoich Klientów (w szczególności dla brokerów
                  ubezpieczeniowych). Firma Prodinity realizowała projekty m.in.
                  dla takich marek jak AON, HSBC, czy Electrolux.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="prodinity-section-3">
          <div className="inner">
            <h2>Strategia</h2>
            <p>
              Po prawie 2 miesiącach prac opracowaliśmy pełną strategię
              komunikacji marki Prodinity na globalny rynek usług IT.
              Ustaliliśmy grupy docelowe, kierunek który należy uwzględnić przy
              projektowaniu nowej identyfikacji wizualnej oraz wszystkie
              kluczowe zagadnienia niezbędne do osiągnięcia przez markę
              założonych celów marketingowo-sprzedażowych.
            </p>
          </div>
          <img
            src={require("../../assets/img/portfolio/prodinity_main_1.png")}
            alt=""
          />
        </section>
        <section className="prodinity-section-4">
          <div className="inner">
            <h2>Logo</h2>
            <p>
              Nowe logo zostało zaprojektowane na bazie strategii komunikacji
              nowej marki. Sygnet prezentuje formujące się bloki poukładanych
              danych, co wprost nawiązuje do głównych działań oferowanych przez
              firmę.
            </p>
          </div>
          <div className="logo-presentation">
            <div className="inner">
              <img
                src={require("../../assets/img/portfolio/prodinity_vertical_logo.svg")}
                alt=""
              />
              <img
                src={require("../../assets/img/portfolio/prodinity_horizontal_logo.svg")}
                alt=""
              />
            </div>
          </div>
          <img
            className="books"
            src={require("../../assets/img/portfolio/prodinity_main_3.png")}
            alt=""
          />
        </section>
        <section className="prodinity-section-5">
          <div className="inner">
            <h2>Strona internetowa</h2>
            <p>
              Zaprojektowaliśmy i zakodowaliśmy nowoczesną i szybką stronę w
              oparciu o technologię ReactJS. Na stronie znajduje się dedykowany
              formularz kontaktowy który posiada wiele wariantów i zmiennych
              zależnych od potrzeb potencjalnych klientów względem realizacji
              projektów z firmą Prodinity.
            </p>
          </div>
          <div className="scroll_wrapper scroll_wrapper--macbook">
            <div className="img_wrapper">
              <img
                src={require("../../assets/img/portfolio/prodinity_full.jpg")}
                className="img-fluid"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </section>
        <section className="prodinity-section-6">
          <div className="top">
            <img
              src={require("../../assets/img/portfolio/prodinity_main_4.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/portfolio/prodinity_main_5.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/portfolio/prodinity_main_6.png")}
              alt=""
            />
          </div>
          <div className="bottom">
            <img
              src={require("../../assets/img/portfolio/prodinity_main_7.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/portfolio/prodinity_main_8.png")}
              alt=""
            />
          </div>
        </section>
        <section className="prodinity-section-7">
          <div className="inner">
            <h2>Stopka email</h2>
            <p>
              Zaprojektowaliśmy i zakodowaliśmy stopki e-mail spójne z nową
              identyfikacją wizualną marki. Stopki zostały zakodowane w dwóch
              wariantach, tak aby działały prawidłowo zarówno dla programów
              pocztowych wspierających kodu HTML jak i tych nie oferujących
              takiego wsparcia np. Microsoft Outlook.
            </p>
          </div>
          <div className="tablet">
            <img
              src={require("../../assets/img/portfolio/prodinity_tablet.png")}
              alt=""
            />
          </div>
        </section>
        <section className="prodinity-section-8">
          <div className="inner">
            <h2>Materiały marketingowe</h2>
            <p>
              W ramach realizacji projektu przygotowaliśmy szereg projektów
              materiałów reklamowych tj. kubki, długopisy, teczki, czy koszulki.
            </p>
          </div>
          <img
            src={require("../../assets/img/portfolio/prodinity_main_9.png")}
            alt=""
          />
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Prodinity;
